import {
  Box,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text
} from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { I18n } from 'galarm-config'
import { StringUtils } from 'galarm-shared'
import DateTimeUtils from '../utils/DateTimeUtils'
import { useSelector } from 'react-redux'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import UserContext from './UserContext'

const AlarmActionSummary = ({ alarmAction }) => {
  if (alarmAction.type === 'trigger') {
    const assigneesString = alarmAction.assignees
      .map(assignee => assignee.name)
      .join(', ')
    return <Text>{I18n.t('alertedAssignees', { assigneesString })}</Text>
  } else if (alarmAction.type === 'ack') {
    return (
      <Text>
        {I18n.t('acknowledgedBy', { memberName: alarmAction.memberName })}
      </Text>
    )
  } else if (alarmAction.type === 'close') {
    return (
      <Text>{I18n.t('closedBy', { memberName: alarmAction.memberName })}</Text>
    )
  } else if (alarmAction.type === 'recover') {
    return <Text>{I18n.t('recovered')}</Text>
  }
}

const ViewIncidentEscalation = ({ incident }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const { user } = useContext(UserContext)

  const [triggers, setTriggers] = useState([])

  const members = useSelector(state => state.accountInfo.members)

  useEffect(() => {
    const alarmsForIncidentQuery = query(
      collection(
        GlobalConfig.firestoreDb,
        'enterpriseAccounts',
        enterpriseAccountId,
        'alerts',
        incident.alertId,
        'alarms'
      ),
      where('incidentId', '==', incident.id)
    )

    const unsubscribe = onSnapshot(alarmsForIncidentQuery, querySnapshot => {
      const incidentTriggers = []
      querySnapshot.forEach(doc => {
        const alarm = doc.data()
        const alarmDate = alarm.date
        const alarmMembers = alarm.members
        const resolvedAssignees = alarmMembers.map(alarmMember => {
          const member = members.find(item => item.id === alarmMember.id)
          return {
            id: member.id,
            name: StringUtils.createDisplayName(
              member.firstName,
              member.lastName
            )
          }
        })
        incidentTriggers.push({
          date: alarmDate,
          assignees: resolvedAssignees
        })
      })
      setTriggers(incidentTriggers)
    })

    return () => unsubscribe()
  }, [])

  let alarmActions = []
  alarmActions = triggers.map(trigger => {
    return {
      ...trigger,
      type: 'trigger'
    }
  })

  if (incident.acknowledgements && incident.acknowledgements.length > 0) {
    alarmActions = alarmActions.concat(
      incident.acknowledgements.map(ack => {
        const { by: acknowledgedBy, at: acknowledgedAt } = ack

        let memberName
        const acknowledgedByMember = members.find(
          item => item.id === acknowledgedBy || item.appUid === acknowledgedBy
        )
        if (acknowledgedByMember) {
          memberName = StringUtils.createDisplayName(
            acknowledgedByMember.firstName,
            acknowledgedByMember.lastName
          )
        } else if (acknowledgedBy === user.uid) {
          memberName = user.displayName
        } else {
          memberName = I18n.t('unknown')
        }

        return {
          date: acknowledgedAt,
          memberName: memberName,
          type: 'ack'
        }
      })
    )
  }

  if (incident.closedBy && incident.closedTimestamp) {
    let memberName
    const closedByMember = members.find(
      item => item.id === incident.closedBy || item.appUid === incident.closedBy
    )
    if (closedByMember) {
      memberName = StringUtils.createDisplayName(
        closedByMember.firstName,
        closedByMember.lastName
      )
    } else if (incident.closedBy === user.uid) {
      memberName = user.displayName
    } else {
      memberName = I18n.t('unknown')
    }

    alarmActions.push({
      date: incident.closedTimestamp,
      memberName: memberName,
      type: 'close'
    })
  }

  if (incident.recoveredTimestamp) {
    alarmActions.push({
      date: incident.recoveredTimestamp,
      type: 'recover'
    })
  }

  alarmActions.sort((a, b) => b.date - a.date)

  return (
    <Box align="center">
      <Heading level={4}>
        {I18n.t('escalationForIncidentsForAlert', {
          alertName: incident.alertName,
          incidentDate: DateTimeUtils.getDateTimeAsString(incident.timestamp)
        })}
      </Heading>
      <Box
        overflow="auto"
        background="white"
        pad="medium"
        round="small"
        margin="medium"
        width="large"
        gap="medium">
        {triggers.length === 0 && (
          <Box pad="small">
            <Text pad="small">{I18n.t('noTriggersForIncident')}</Text>
          </Box>
        )}
        {triggers.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  {I18n.t('date')}
                </TableCell>
                <TableCell scope="col" border="bottom">
                  {I18n.t('action')}
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {alarmActions.map((alarmAction, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell scope="row">
                      <Text weight={500}>
                        {DateTimeUtils.getDateTimeAsString(alarmAction.date)}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <AlarmActionSummary alarmAction={alarmAction} />
                      <Text></Text>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  )
}

export default ViewIncidentEscalation
