import {
  Box,
  Button,
  DateInput,
  Form,
  FormField,
  Heading,
  Layer,
  Select,
  Text,
  TextInput
} from 'grommet'
import React, { useState } from 'react'
import ErrorProvider from './ErrorProvider'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { stringNotEmpty } from '../utils/validations'
import { AlarmUtils, StringUtils } from 'galarm-shared'
import FormFieldWithNoBorder from './FormFieldWithNoBorder'
import FormFieldWithAllBorders from './FormFieldWithAllBorders'
import { useSelector } from 'react-redux'
import { Close, Subtract } from 'grommet-icons'
import moment from 'moment-timezone'
import EditWeeksRotation from './EditWeeksRotation'
import EditDaysRotation from './EditDaysRotation'
import EditHoursRotation from './EditHoursRotation'
import TimeInput from './TimeInput'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import RotationCalendar from './RotationCalendar'
import EditShift from './EditShift'
import { SecondaryText } from 'web-components'

const EditRotation = ({ isEdit, rotation, onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const INITIAL_STATE = isEdit
    ? {
        id: rotation.id,
        name: rotation.name,
        timezone: rotation.timezone,
        rotationType: rotation.rotationType,
        rotationParams: rotation.rotationParams,
        members: Object.values(rotation.members)
          .sort((member1, member2) => member2.index - member1.index)
          .map(member => ({ id: member.id })),
        startDate: rotation.startDate,
        startTime: rotation.startTime
      }
    : {
        id: doc(
          collection(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'rotations'
          )
        ).id,
        name: '',
        timezone: '',
        rotationType: Constants.ROTATION_TYPES.WEEKS,
        rotationParams: {},
        members: [],
        startDate: moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .valueOf(),
        startTime: '8:00:am'
      }

  const [value, setValue] = useState(INITIAL_STATE)

  const [timezoneSearchText, setTimezoneSearchText] = useState('')

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const [showRotationCalendar, setShowRotationCalendar] = useState(false)
  // const displayRotationCalendar = () => setShowRotationCalendar(true)
  const hideRotationCalendar = () => setShowRotationCalendar(false)

  const [showAddShiftDialog, setShowAddShiftDialog] = useState(false)
  const displayAddShiftDialog = () => setShowAddShiftDialog(true)
  const hideAddShiftDialog = () => setShowAddShiftDialog(false)

  const members = useSelector(state => state.accountInfo.members)
  const membersWithLicense = members.filter(member => member.subscriptionKey)
  const memberOptions = membersWithLicense.map(member => ({
    label: StringUtils.createDisplayName(member.firstName, member.lastName),
    value: member.id
  }))

  const computeShifts = rotation => {
    console.log('computeShifts', rotation)
    const shifts = []
    const members = rotation.members
    const rotationType = rotation.rotationType
    const rotationParams = rotation.rotationParams
    const startDate = moment(rotation.startDate)
    const startTime = moment(rotation.startTime, 'hh:mm:a')
    const shiftStartDate = moment().tz(rotation.timezone)

    // Set the start date
    shiftStartDate.year(startDate.year())
    shiftStartDate.month(startDate.month())
    shiftStartDate.date(startDate.date())
    // Set the start time
    shiftStartDate.hours(startTime.hours())
    shiftStartDate.minutes(startTime.minutes())
    shiftStartDate.seconds(0)
    shiftStartDate.milliseconds(0)

    const startTimeInMilliseconds = shiftStartDate.valueOf()

    console.log(
      'startTimeInMilliseconds',
      startTimeInMilliseconds,
      moment(startTimeInMilliseconds)
        .tz(rotation.timezone)
        .format('YYYY-MM-DD HH:mm:ss'),
      moment(startTimeInMilliseconds).format('YYYY-MM-DD HH:mm:ss')
    )

    // Compute shifts for one year
    const endTimeInMilliseconds =
      startTimeInMilliseconds + 365 * 24 * 60 * 60 * 1000

    let shiftDurationInMilliseconds = 0
    if (rotationType === Constants.ROTATION_TYPES.HOURS) {
      const shiftDuration = parseInt(rotationParams.numHoursInShift)
      shiftDurationInMilliseconds = shiftDuration * 60 * 60 * 1000
    } else if (rotationType === Constants.ROTATION_TYPES.DAYS) {
      const shiftDuration = parseInt(rotationParams.numDaysInShift)
      shiftDurationInMilliseconds = shiftDuration * 24 * 60 * 60 * 1000
    } else if (rotationType === Constants.ROTATION_TYPES.WEEKS) {
      const shiftDuration = parseInt(rotationParams.numWeeksInShift)
      shiftDurationInMilliseconds = shiftDuration * 7 * 24 * 60 * 60 * 1000
    }

    let shiftEndTime
    for (
      let shiftStartTime = startTimeInMilliseconds, i = 0;
      shiftStartTime < endTimeInMilliseconds;
      shiftStartTime += shiftDurationInMilliseconds, i++
    ) {
      shiftEndTime = shiftStartTime + shiftDurationInMilliseconds
      if (shiftEndTime > endTimeInMilliseconds) {
        shiftEndTime = shiftStartTime
        break
      }
      const assignee = members[i % members.length].id
      const shift = {
        // auto-assign an id from firestore
        id: doc(collection(GlobalConfig.firestoreDb, 'genIds')).id,
        start: shiftStartTime,
        end: shiftEndTime,
        assignee: assignee
      }
      shifts.push(shift)
    }
    console.log('shifts', shifts)

    return { shifts, rotationExpirationDate: shiftEndTime }
  }

  const onEditRotation = async ({ value: rotation }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: isEdit ? I18n.t('editingRotation') : I18n.t('addingRotation'),
      closeable: false
    })

    const numMembers = rotation.members.length
    if (numMembers === 0) {
      addError(I18n.t('rotationMustHaveMembers'))
      GlobalConfig.hideProgress()
      return
    }

    const { shifts, rotationExpirationDate } = computeShifts(rotation)
    try {
      if (isEdit) {
        await updateDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'rotations',
            rotation.id
          ),
          { ...rotation, shifts, rotationExpirationDate }
        )
      } else {
        await setDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'rotations',
            rotation.id
          ),
          { ...rotation, shifts, rotationExpirationDate }
        )
      }
      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        isEdit
          ? Constants.UserAnalyticsEvents.EDIT_ROTATION
          : Constants.UserAnalyticsEvents.ADD_ROTATION,
        {}
      )
      onClose()
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const chooseTimezone = timezone => {
    setValue({
      ...value,
      timezone
    })
  }

  const onSearchTimezone = text => {
    setTimezoneSearchText(text)
  }

  const chooseRotationType = rotationType => {
    setValue({
      ...value,
      rotationType,
      rotationParams: []
    })
  }

  const onSaveStartDate = ({ value: startDate }) => {
    setValue({
      ...value,
      startDate: moment(startDate).valueOf()
    })
  }

  const onSaveStartTime = startTime => {
    setValue({
      ...value,
      startTime
    })
  }

  const onUpdateRotation = newValue => setValue(newValue)

  const addMember = () => {
    const newMembers = value.members.slice()
    newMembers.push({})
    setValue({
      ...value,
      members: newMembers
    })
  }

  const onSaveMember = (memberId, index) => {
    const member = { ...value.members[index] }
    member.id = memberId
    member.order = index
    const newMembers = value.members.slice()
    newMembers[index] = member
    setValue({
      ...value,
      members: newMembers
    })
  }

  const onRemoveMember = index => {
    const newMembers = value.members.slice()
    newMembers.splice(index, 1)
    setValue({
      ...value,
      members: newMembers
    })
  }

  const timezoneOptions = AlarmUtils.getAllTimezones().filter(timezone =>
    timezone.label.toLowerCase().includes(timezoneSearchText.toLowerCase())
  )

  const rotationTypeOptions = [
    {
      label: I18n.t('snoozeUnitHours'),
      value: Constants.ROTATION_TYPES.HOURS
    },
    {
      label: I18n.t('days'),
      value: Constants.ROTATION_TYPES.DAYS
    },
    {
      label: I18n.t('weeks'),
      value: Constants.ROTATION_TYPES.WEEKS
    }
  ]

  console.log('EditRotation', value)

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center" flex={{ shrink: 0 }}>
        <Heading level={4}>
          {isEdit ? I18n.t('editRotation') : I18n.t('addRotation')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={rotation => {
              onEditRotation(rotation)
            }}>
            <FormField
              htmlFor="name"
              name="name"
              label={
                <SecondaryText weight={500}>{I18n.t('name')}</SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('name'))}>
              <TextInput
                id="name"
                name="name"
                placeholder={I18n.t('enterRotationName')}
              />
            </FormField>
            <FormFieldWithAllBorders
              htmlFor={'timezone'}
              name={'timezone'}
              label={
                <SecondaryText weight={500}>{I18n.t('timezone')}</SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('timezone'))}>
              <Select
                id={'timezone'}
                name={'timezone'}
                options={timezoneOptions}
                placeholder={I18n.t('selectPlaceholder')}
                value={value.timezone}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ option }) => {
                  chooseTimezone(option.value)
                }}
                onSearch={text => onSearchTimezone(text)}
                onClose={() => setTimezoneSearchText('')}
              />
            </FormFieldWithAllBorders>
            <FormFieldWithAllBorders
              htmlFor={'rotationType'}
              name={'rotationType'}
              label={
                <SecondaryText weight={500}>
                  {I18n.t('rotationType')}
                </SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('rotationType'))}>
              <Select
                id={'rotationType'}
                name={'rotationType'}
                options={rotationTypeOptions}
                placeholder={I18n.t('selectPlaceholder')}
                value={value.rotationType}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ option }) => {
                  chooseRotationType(option.value)
                }}
              />
            </FormFieldWithAllBorders>
            {value.rotationType === Constants.ROTATION_TYPES.HOURS && (
              <EditHoursRotation
                value={value}
                onUpdateRotation={onUpdateRotation}
              />
            )}
            {value.rotationType === Constants.ROTATION_TYPES.DAYS && (
              <EditDaysRotation
                value={value}
                onUpdateRotation={onUpdateRotation}
              />
            )}
            {value.rotationType === Constants.ROTATION_TYPES.WEEKS && (
              <EditWeeksRotation
                value={value}
                onUpdateRotation={onUpdateRotation}
              />
            )}
            <Box direction="row" gap="medium">
              <FormField
                htmlFor="startDate"
                name="startDate"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('startDateForRotation')}
                  </SecondaryText>
                }
                validate={stringNotEmpty.bind(
                  null,
                  I18n.t('startDateForRotation')
                )}>
                <DateInput
                  format="mm/dd/yyyy"
                  value={new Date(value.startDate).toISOString()}
                  onChange={onSaveStartDate}
                />
              </FormField>
              <FormFieldWithNoBorder
                htmlFor="startTime"
                name="startTime"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('startTimeForRotation')}
                  </SecondaryText>
                }
                validate={stringNotEmpty.bind(
                  null,
                  I18n.t('startTimeForRotation')
                )}>
                <TimeInput time={value.startTime} onChange={onSaveStartTime} />
              </FormFieldWithNoBorder>
            </Box>
            {value.members.length > 0 && (
              <SecondaryText weight={500} margin="small">
                {I18n.t('members')}
              </SecondaryText>
            )}
            {value.members.map((member, index) => {
              const memberId = `members[${index}].id`

              return (
                <Box
                  key={index}
                  direction="row"
                  gap="small"
                  align="center"
                  margin="small">
                  <Text>{index + 1}</Text>
                  <FormFieldWithAllBorders
                    htmlFor={memberId}
                    name={memberId}
                    validate={stringNotEmpty.bind(null, I18n.t('member'))}>
                    <Select
                      id={memberId}
                      name={memberId}
                      options={memberOptions}
                      placeholder={I18n.t('selectPlaceholder')}
                      value={member.id}
                      labelKey="label"
                      valueKey={{ key: 'value', reduce: true }}
                      onChange={({ option }) => {
                        onSaveMember(option.value, index)
                      }}
                    />
                  </FormFieldWithAllBorders>
                  <Button
                    primary
                    onClick={() => onRemoveMember(index)}
                    icon={<Subtract size="small" color="white" />}
                  />
                </Box>
              )
            })}
            <Box>
              <Button
                plain
                margin={{ vertical: 'medium' }}
                color="primary"
                onClick={addMember}
                alignSelf="start"
                label={I18n.t('addMember')}
              />
              <Box
                direction="row"
                gap="medium"
                justify="center"
                margin="medium">
                <Button
                  type="submit"
                  primary
                  style={{ color: 'white' }}
                  label={isEdit ? I18n.t('save') : I18n.t('add')}
                />
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
      {/* These are currently not shown */}
      {showRotationCalendar && (
        <Layer background="lightTint" margin="medium">
          <Box margin="medium">
            <RotationCalendar
              rotation={rotation}
              onClose={() => setShowRotationCalendar(false)}
            />
            <Box style={{ position: 'absolute', top: 10, right: 20 }}>
              <Close onClick={hideRotationCalendar} />
            </Box>
          </Box>
          <Button
            plain
            margin={{ horizontal: 'medium', bottom: 'medium' }}
            color="primary"
            onClick={displayAddShiftDialog}
            alignSelf="start"
            label={I18n.t('addShift')}
          />
        </Layer>
      )}
      {showAddShiftDialog && (
        <Layer
          background="lightTint"
          margin="medium"
          onClickOutside={hideAddShiftDialog}
          onEsc={hideAddShiftDialog}>
          <Box margin="medium">
            <EditShift
              isEdit={false}
              rotation={rotation}
              onClose={() => {
                hideAddShiftDialog()
                hideRotationCalendar()
              }}
            />
            <Box style={{ position: 'absolute', top: 10, right: 20 }}>
              <Close onClick={hideAddShiftDialog} />
            </Box>
          </Box>
        </Layer>
      )}
    </ErrorProvider>
  )
}

export default EditRotation
