export const validateEmail = email => {
  if (!email) {
    return 'Email is empty'
  }
}

export const validatePassword = password => {
  if (!password) {
    return 'Password is empty'
  }
}

export const validateConfirmedPassword = (password, confirmedPassword) => {
  if (password !== confirmedPassword) {
    return "Passwords don't match"
  }
}

export const validateFirstName = name => {
  if (!name) {
    return 'First name is empty'
  }
}

export const validateLastName = name => {
  if (!name) {
    return 'Last name is empty'
  }
}

export const validatePhoneNumber = name => {
  if (!name) {
    return 'Phone number is empty'
  }
}

export const stringNotEmpty = (label, str) => {
  if (!str) {
    return `${label} is empty`
  }
}

export const isNumber = (label, str) => {
  if (!str || isNaN(str)) {
    return `${label} is not a number`
  }
}
