import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Layer,
  ResponsiveContext,
  Select,
  Text,
  TextInput
} from 'grommet'
import React, { useContext, useState } from 'react'
import ErrorProvider from './ErrorProvider'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { stringNotEmpty } from '../utils/validations'
import { useSelector } from 'react-redux'
import UserContext from './UserContext'
import FormFieldWithAllBorders from './FormFieldWithAllBorders'
import ReactSelect from 'react-select'
import { Subtract } from 'grommet-icons'
import { StringUtils } from 'galarm-shared'
import { isEmpty } from 'lodash'
import { ChooseDurationContainer, SecondaryText } from 'web-components'
import { collection, doc, setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import DateTimeUtils from '../utils/DateTimeUtils'
import { logEvent } from 'firebase/analytics'

// const severityOptions = [
//   { label: I18n.t('low'), value: 'low' },
//   { label: I18n.t('medium'), value: 'medium' },
//   { label: I18n.t('high'), value: 'high' }
// ]

const EditAlert = ({ isEdit, alert, onClose, displayApiCodeDialog }) => {
  const { user } = useContext(UserContext)
  const size = useContext(ResponsiveContext)

  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const integrationConfigs = useSelector(state =>
    state.accountInfo.integrationConfigs.map(config => ({
      id: config.id,
      name: config.name
    }))
  )

  const existingIncidentPrefixes = useSelector(
    state => state.accountInfo.incidentPrefixes
  )

  const alertId = isEdit
    ? alert.id
    : doc(
        collection(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'alerts'
        )
      ).id

  const INITIAL_STATE = isEdit
    ? {
        id: alertId,
        name: alert.name,
        severity: alert.severity,
        triggers: alert.triggers,
        integrations: alert.integrations.map(id =>
          integrationConfigs.find(config => config.id === id)
        ),
        preferredSourceForIncidents: alert.preferredSourceForIncidents || '',
        incidentPrefix: alert.incidentPrefix || ''
      }
    : {
        id: alertId,
        name: '',
        severity: 'high',
        triggers: [
          {
            id: doc(
              collection(
                GlobalConfig.firestoreDb,
                'enterpriseAccounts',
                enterpriseAccountId,
                'alerts',
                alertId,
                'triggers'
              )
            ).id,
            delay: 0,
            assignee: {}
          }
        ],
        integrations: [],
        preferredSourceForIncidents: '',
        incidentPrefix: ''
      }

  const [value, setValue] = useState(INITIAL_STATE)

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const [showChooseDelayDialog, setShowChooseDelayDialog] = useState({})
  const displayChooseDelayDialog = triggerIndex =>
    setShowChooseDelayDialog({ triggerIndex })
  const hideChooseDelayDialog = () => setShowChooseDelayDialog({})

  const members = useSelector(state => state.accountInfo.members)
  const membersWithLicense = members.filter(member => member.subscriptionKey)
  const memberOptions = membersWithLicense.map(member => ({
    label: StringUtils.createDisplayName(member.firstName, member.lastName),
    value: member.id,
    assigneeType: Constants.ASSIGNEE_TYPES.MEMBER
  }))

  const teams = useSelector(state => state.accountInfo.teams)
  const teamOptions = teams.map(team => ({
    label: team.name,
    value: team.id,
    assigneeType: Constants.ASSIGNEE_TYPES.TEAM
  }))

  const rotations = useSelector(state => state.accountInfo.rotations)
  const rotationOptions = rotations.map(rotation => ({
    label: rotation.name,
    value: rotation.id,
    assigneeType: Constants.ASSIGNEE_TYPES.ROTATION
  }))

  const assigneeOptions = [
    {
      label: I18n.t('members'),
      options: memberOptions
    },
    {
      label: I18n.t('teams'),
      options: teamOptions
    },
    {
      label: I18n.t('rotations'),
      options: rotationOptions
    }
  ]

  const delayOptions = [
    {
      label: I18n.t('atTimeOfEvent'),
      value: 0
    },
    {
      label: I18n.t('afterNMinutes', { count: 30 }),
      value: 30 * Constants.MSEC_IN_MINUTE
    },
    {
      label: I18n.t('afterNHours', { count: 1 }),
      value: Constants.MSEC_IN_HOUR
    },
    {
      label: I18n.t('afterNHours', { count: 4 }),
      value: 4 * Constants.MSEC_IN_HOUR
    }
  ]

  const preferredSourceForIncidentsOptions = [
    {
      label: 'Curl No Data Basic Auth',
      value: Constants.SOURCES_FOR_INCIDENTS.CURL_NO_DATA_BASIC_AUTH
    },
    {
      label: 'Curl No Data Bearer Token',
      value: Constants.SOURCES_FOR_INCIDENTS.CURL_NO_DATA_BEARER_TOKEN
    },
    {
      label: 'Curl With Data Basic Auth',
      value: Constants.SOURCES_FOR_INCIDENTS.CURL_WITH_DATA_BASIC_AUTH
    },
    {
      label: 'Curl With Data Bearer Token',
      value: Constants.SOURCES_FOR_INCIDENTS.CURL_WITH_DATA_BEARER_TOKEN
    },
    {
      label: 'Datadog',
      value: Constants.SOURCES_FOR_INCIDENTS.DATA_DOG
    },
    {
      label: 'AWS SNS',
      value: Constants.SOURCES_FOR_INCIDENTS.AWS_SNS
    }
  ]

  value.triggers.forEach(trigger => {
    if (
      trigger.delay &&
      delayOptions.findIndex(
        delayOption => delayOption.value === trigger.delay
      ) === -1
    ) {
      delayOptions.push({
        label: I18n.t('afterDuration', {
          durationString: DateTimeUtils.getDurationAsString(trigger.delay)
        }),
        value: trigger.delay
      })
    }
  })

  const sortedDelayOptions = delayOptions.sort(
    (option1, option2) => option1.value - option2.value
  )

  sortedDelayOptions.push({
    label: I18n.t('specify'),
    value: -1
  })

  const onSaveDelayForTrigger = (selectedOption, index) => {
    if (selectedOption === -1) {
      displayChooseDelayDialog(index)
    } else {
      const newTriggers = value.triggers.slice()
      newTriggers[index].delay = selectedOption
      setValue({
        ...value,
        triggers: newTriggers
      })
    }
  }

  const onSaveAssigneeForTrigger = (selectedOption, index) => {
    const assignee = {
      type: selectedOption.assigneeType,
      id: selectedOption.value,
      name: selectedOption.label
    }
    const newTriggers = value.triggers.slice()
    newTriggers[index].assignee = assignee
    setValue({
      ...value,
      triggers: newTriggers
    })
  }

  const addTrigger = () => {
    const newTriggers = value.triggers.slice()
    newTriggers.push({
      id: uuidv4(),
      delay: '',
      assignee: {}
    })
    setValue({
      ...value,
      triggers: newTriggers
    })
  }

  const onRemoveTrigger = index => {
    const newTriggers = value.triggers.slice()
    newTriggers.splice(index, 1)
    setValue({
      ...value,
      triggers: newTriggers
    })
  }

  const getDisplayTextForTriggerDelay = duration =>
    I18n.t('assigneeWillBeAlertedAfterDelay', {
      durationString: DateTimeUtils.getDurationAsString(duration)
    })

  const onEditAlert = async ({ value }) => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: isEdit ? I18n.t('editingAlert') : I18n.t('addingAlert'),
        closeable: false
      })

      const {
        id,
        name,
        severity,
        triggers: allTriggers,
        integrations,
        preferredSourceForIncidents,
        incidentPrefix
      } = value

      // remove triggers with empty assignee or delay
      const triggers = allTriggers.filter(
        trigger => !isEmpty(trigger.assignee) && trigger.delay !== ''
      )

      if (triggers.length === 0) {
        addError(I18n.t('listenerMustHaveTriggers'))
        GlobalConfig.hideProgress()
        return
      }

      const assignees = triggers.map(trigger => trigger.assignee.id)

      const newAlert = {
        id,
        name,
        severity,
        assignees,
        triggers,
        integrations,
        preferredSourceForIncidents,
        notificationOrder: Constants.NOTIFICATION_ORDERS.SIMULTANEOUS,
        resolutionCriteria: Constants.RESOLUTION_CRITERIA.ANY_ONE,
        owner: user.uid,
        incidentPrefix: incidentPrefix || ''
      }

      await setDoc(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'alerts',
          id
        ),
        newAlert
      )
      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        isEdit
          ? Constants.UserAnalyticsEvents.EDIT_ALERT
          : Constants.UserAnalyticsEvents.ADD_ALERT,
        {}
      )

      onClose()
      !isEdit && displayApiCodeDialog(newAlert)
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const validateIncidentPrefix = incidentPrefix => {
    if (isEdit && alert.incidentPrefix) {
      return
    }

    if (existingIncidentPrefixes.includes(incidentPrefix)) {
      return I18n.t('incidentPrefixAlreadyExists')
    }
    if (incidentPrefix.length > 5) {
      return I18n.t('incidentPrefixLengthExceeded')
    }
  }

  // const onIntegrationsChange = selectedIntegrations => {
  //   setValue({
  //     ...value,
  //     integrations: selectedIntegrations
  //   })
  // }

  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      minWidth: '250px'
    })
  }

  console.log('EditAlert', value)

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center" flex={{ shrink: 0 }}>
        <Heading level={4}>
          {isEdit ? I18n.t('editAlert') : I18n.t('addAlert')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={alertData => {
              onEditAlert(alertData)
            }}>
            <FormField
              htmlFor="name"
              name="name"
              label={
                <SecondaryText weight={500}>{I18n.t('name')}</SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('name'))}>
              <TextInput
                id="name"
                name="name"
                placeholder={I18n.t('enterAlertName')}
              />
            </FormField>
            <FormField
              disabled={isEdit && alert.incidentPrefix}
              htmlFor="incidentPrefix"
              name="incidentPrefix"
              label={
                <SecondaryText weight={500}>
                  {I18n.t('incidentPrefix')}
                </SecondaryText>
              }
              validate={validateIncidentPrefix}>
              <TextInput
                id="incidentPrefix"
                name="incidentPrefix"
                placeholder={I18n.t('enterIncidentPrefix')}
                disabled={isEdit && alert.incidentPrefix}
              />
            </FormField>
            <FormFieldWithAllBorders
              htmlFor="preferredSourceForIncidents"
              name="preferredSourceForIncidents"
              label={
                <SecondaryText weight={500}>
                  {I18n.t('preferredSourceForIncidents')}
                </SecondaryText>
              }
              validate={stringNotEmpty.bind(
                null,
                I18n.t('preferredSourceForIncidents')
              )}>
              <Select
                id="preferredSourceForIncidents"
                name="preferredSourceForIncidents"
                options={preferredSourceForIncidentsOptions}
                placeholder={I18n.t('selectPlaceholder')}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ option }) => {
                  setValue({
                    ...value,
                    preferredSourceForIncidents: option.value
                  })
                }}
              />
            </FormFieldWithAllBorders>
            {/* <FormFieldWithAllBorders
              htmlFor="severity"
              name="severity"
              label={I18n.t('severity')}
              validate={stringNotEmpty.bind(null, I18n.t('severity'))}>
              <Select
                id="severity"
                name="severity"
                options={severityOptions}
                placeholder={I18n.t('selectPlaceholder')}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ option }) => {
                  setValue({
                    ...value,
                    severity: option.value
                  })
                }}
              />
            </FormFieldWithAllBorders> */}
            {/* <FormFieldWithAllBorders
              htmlFor="integrations"
              name="integrations"
              label={I18n.t('integrations')}>
              <ReactSelect
                id="integrations"
                name="integrations"
                styles={customStyles}
                isMulti
                options={integrationConfigs}
                getOptionLabel={integrationConfig => integrationConfig.name}
                onChange={onIntegrationsChange}
                value={value.integrations}
              />
            </FormFieldWithAllBorders> */}
            <Box pad={{ top: 'xsmall' }}>
              <SecondaryText weight={500} margin={{ horizontal: 'small' }}>
                {I18n.t('escalationPolicy')}
              </SecondaryText>
              {value.triggers?.map((trigger, index) => {
                const delayId = `triggers[${index}].delay`
                const assigneeId = `triggers[${index}].assignee.id`

                let triggerAssigneeValue
                if (!isEmpty(trigger.assignee)) {
                  triggerAssigneeValue = {
                    label: trigger.assignee.name,
                    value: trigger.assignee.id,
                    assigneeType: trigger.assignee.type
                  }
                }

                return (
                  <Box
                    key={index}
                    direction={size === 'small' ? 'column' : 'row'}
                    gap="small"
                    align="center"
                    margin="small">
                    <Text>{I18n.t('triggerName', { index: index + 1 })}</Text>
                    <FormFieldWithAllBorders
                      htmlFor={delayId}
                      name={delayId}
                      margin="none"
                      // validate={stringNotEmpty.bind(null, I18n.t('delay'))}
                    >
                      <Select
                        id={delayId}
                        name={delayId}
                        options={
                          index === 0
                            ? sortedDelayOptions
                            : sortedDelayOptions.slice(1)
                        }
                        placeholder={I18n.t('selectPlaceholder')}
                        value={trigger.delay}
                        labelKey="label"
                        valueKey={{ key: 'value', reduce: true }}
                        onChange={({ option }) => {
                          onSaveDelayForTrigger(option.value, index)
                        }}
                      />
                    </FormFieldWithAllBorders>
                    <FormFieldWithAllBorders
                      htmlFor={assigneeId}
                      name={assigneeId}
                      margin="none"
                      // validate={stringNotEmpty.bind(null, I18n.t('assignee'))}
                    >
                      <ReactSelect
                        id={assigneeId}
                        name={assigneeId}
                        styles={customStyles}
                        placeholder={I18n.t('chooseAssigneePlaceholder')}
                        options={assigneeOptions}
                        getOptionLabel={assignee => assignee.label}
                        onChange={option =>
                          onSaveAssigneeForTrigger(option, index)
                        }
                        value={triggerAssigneeValue}
                      />
                    </FormFieldWithAllBorders>
                    <Button
                      primary
                      onClick={() => onRemoveTrigger(index)}
                      icon={<Subtract color="white" size="small" />}
                    />
                  </Box>
                )
              })}
              <Button
                plain
                margin="small"
                color="primary"
                alignSelf="start"
                onClick={addTrigger}
                label={I18n.t('addTrigger')}
              />
            </Box>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={isEdit ? I18n.t('save') : I18n.t('add')}
              />
            </Box>
          </Form>
        </Box>
        {!isEmpty(showChooseDelayDialog) && (
          <Layer
            background="lightTint"
            onEsc={hideChooseDelayDialog}
            onClickOutside={hideChooseDelayDialog}>
            <Box margin="medium">
              <ChooseDurationContainer
                onSaveDuration={duration => {
                  onSaveDelayForTrigger(
                    duration,
                    showChooseDelayDialog.triggerIndex
                  )
                  hideChooseDelayDialog()
                }}
                getDisplayTextForDuration={duration =>
                  getDisplayTextForTriggerDelay(duration)
                }
                title={I18n.t('delayForTrigger')}
                onClose={hideChooseDelayDialog}
              />
            </Box>
          </Layer>
        )}
      </Box>
    </ErrorProvider>
  )
}

export default EditAlert
