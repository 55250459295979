import 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/functions'
import 'firebase/compat/remote-config'

import React from 'react'
import { createRoot } from 'react-dom/client'
import GlobalConfig from './GlobalConfig'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import { ReactNotifications } from 'react-notifications-component'

import 'react-notifications-component/dist/theme.css'

const { store } = configureStore()
GlobalConfig.store = store

const rootContainer = document.getElementById('root')
const root = createRoot(rootContainer)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactNotifications />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
