import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Layer,
  List,
  Menu,
  Text
} from 'grommet'
import React, { useContext, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { Add, Close, MoreVertical } from 'grommet-icons'
import { useSelector } from 'react-redux'
import EditRotation from './EditRotation'
import { isEmpty } from 'lodash'
import ErrorProvider from './ErrorProvider'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import WithRoles from './WithRoles'
import UserContext from './UserContext'
import { DateTimeUtils } from 'galarm-shared'

const RotationSummary = ({ item, index, onEditRotation, onDeleteRotation }) => {
  const menuItems = [
    { label: I18n.t('edit'), onClick: () => onEditRotation(item) },
    { label: I18n.t('delete'), onClick: () => onDeleteRotation(item) }
  ]

  const rotationExpirationDate = item.rotationExpirationDate
  const rotationExpirationDateText = rotationExpirationDate
    ? I18n.t('rotationExpiresOn', {
        dateString: DateTimeUtils.getDateAsString(rotationExpirationDate)
      })
    : I18n.t('editAndSaveRotationToSetExpirationDate')

  // Show in red if rotation expiring in 28 days or less
  const rotationExpirationDateColor =
    rotationExpirationDate &&
    DateTimeUtils.isDateInNextDays(rotationExpirationDate, 28)
      ? 'warningTextColor'
      : 'darkTint'

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background={index % 2 ? 'lightTint' : 'white'}
      pad="small">
      <Box gap="xxsmall">
        <Text>{item.name}</Text>
        <Text size="small" color={rotationExpirationDateColor}>
          {rotationExpirationDateText}
        </Text>
      </Box>
      <Menu
        dropBackground="lightTint"
        alignSelf="start"
        size="small"
        icon={<MoreVertical color="textColor" />}
        items={menuItems}
      />
    </Box>
  )
}

const Rotations = () => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const { userRoles } = useContext(UserContext)

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const [showEditRotationDialog, setShowEditRotationDialog] = useState({})
  const displayAddRotationDialog = () =>
    setShowEditRotationDialog({ isEdit: false, rotation: {} })
  const displayEditRotationDialog = rotation =>
    setShowEditRotationDialog({ isEdit: true, rotation })
  const hideEditRotationDialog = () => setShowEditRotationDialog({})

  const rotations = useSelector(state => state.accountInfo.rotations)

  const onEditRotation = rotation => {
    displayEditRotationDialog(rotation)
  }

  const onDeleteRotation = async rotation => {
    try {
      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('deletingRotation'),
        closeable: false
      })

      const rotationAssigneeQuery = query(
        collection(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'alerts'
        ),
        where('assignees', 'array-contains', rotation.id)
      )
      const rotationAssigneeSnapshot = await getDocs(rotationAssigneeQuery)
      if (!rotationAssigneeSnapshot.empty) {
        throw new Error(
          I18n.t('cantDeleteRotationAssignedToAlerts', {
            count: rotationAssigneeSnapshot.size
          })
        )
      }

      await deleteDoc(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'rotations',
          rotation.id
        )
      )

      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        Constants.UserAnalyticsEvents.DELETE_ROTATION,
        {}
      )
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const sortedRotations = rotations.sort((rotation1, rotation2) =>
    rotation1.name.localeCompare(rotation2.name)
  )

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box
        pad="small"
        gap="small"
        width={{ max: 'large' }}
        flex={{ shrink: 0 }}>
        <Box direction="row" justify="between">
          <Heading margin="small" level={3}>
            {I18n.t('rotations')}
          </Heading>
          <WithRoles
            requiredRoles={[
              Constants.ENTERPRISE_USER_ROLES.OWNER,
              Constants.ENTERPRISE_USER_ROLES.ADMIN
            ]}
            assignedRoles={userRoles}>
            <Button
              primary
              icon={<Add color="white" />}
              label={I18n.t('rotation')}
              style={{ color: 'white' }}
              onClick={displayAddRotationDialog}
            />
          </WithRoles>
        </Box>
        <Card background="textBackgroundColor">
          <CardBody margin="none">
            {rotations.length === 0 ? (
              <Text margin="small">
                {I18n.t('noRotationsConfiguredInEnterpriseAccount')}
              </Text>
            ) : (
              <List
                border={false}
                pad="none"
                margin="none"
                data={sortedRotations}>
                {(item, index) => (
                  <RotationSummary
                    item={item}
                    index={index}
                    onEditRotation={onEditRotation}
                    onDeleteRotation={onDeleteRotation}
                  />
                )}
              </List>
            )}
          </CardBody>
        </Card>
      </Box>
      {!isEmpty(showEditRotationDialog) && (
        <Layer margin="medium" background="lightTint">
          <Box margin="medium">
            <EditRotation
              isEdit={showEditRotationDialog.isEdit}
              rotation={showEditRotationDialog.rotation}
              onClose={hideEditRotationDialog}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideEditRotationDialog} />
            </Box>
          </Box>
        </Layer>
      )}
    </ErrorProvider>
  )
}

export default Rotations
