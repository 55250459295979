import { getFunctions, httpsCallable } from 'firebase/functions'
import { Spinner } from 'grommet'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import ErrorProvider from './ErrorProvider'
import { Constants, I18n } from 'galarm-config'
import { useLocation } from 'react-router-dom'
import GlobalConfig from '../GlobalConfig'

const BillingPortal = () => {
  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const location = useLocation()
  const customerId = location.state?.customerId

  useLayoutEffect(() => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('openingBillingPortal'),
      closeable: false
    })
  }, [])

  useEffect(() => {
    async function createCustomerPortalSession() {
      if (!customerId) {
        GlobalConfig.hideProgress()
        addError(I18n.t('problemWithEnterpriseAccountSetup'))
        return
      }

      const functions = getFunctions()
      const stripeCreateCustomerPortalSession = httpsCallable(
        functions,
        'stripeCreateCustomerPortalSession'
      )

      try {
        const resultSession = await stripeCreateCustomerPortalSession({
          customerId: customerId
        })

        console.log('customerPortalSession', resultSession.data.session)
        GlobalConfig.hideProgress()
        window.location.href = resultSession.data.session.url
      } catch (error) {
        GlobalConfig.hideProgress()
        addError(I18n.t('problemWithCreatingCustomerPortalSession'))
        return
      }
    }
    createCustomerPortalSession()
  }, [])

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Spinner />
    </ErrorProvider>
  )
}

export default BillingPortal
