import moment from 'moment-timezone'
import { I18n } from 'galarm-config'

const DateTimeUtils = (function () {
  // Change the format for this as well
  const getDateTimeAsString = function (date) {
    return moment(date).format('D MMM YYYY, h:mm A')
  }

  const getDateTimeWoYearAsString = function (date) {
    return moment(date).format('D MMM, h:mm A')
  }

  const getTimeAsString = function (date) {
    return moment(date).format('h:mm A')
  }

  const getDateAsString = function (date) {
    return moment(date).format('D MMM YYYY')
  }

  const getDurationAsString = function (
    durationInMsecs,
    emptyDurationString = I18n.t('lessThanMinute')
  ) {
    const duration = moment.duration(durationInMsecs)
    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const durationString =
      '' +
      (years > 0 ? I18n.t('numYears', { count: years }) + ' ' : '') +
      (months > 0 ? I18n.t('numMonths', { count: months }) + ' ' : '') +
      (days > 0 ? I18n.t('numDays', { count: days }) + ' ' : '') +
      (hours > 0 ? I18n.t('hours', { count: hours }) + ' ' : '') +
      (minutes > 0 ? I18n.t('minutes', { count: minutes }) + ' ' : '')
    return durationString !== '' ? durationString.trim() : emptyDurationString
  }

  // Give the start and end date of the calendar shown on screen
  // which displays 35 days
  const getCalendarWeekRangeForADate = function (date) {
    // Start day in the calendar month
    const start = moment(date)
      .day(0)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)

    // End day in the calendar month. Add 1 to get to the end of the day on the calendar month
    const end = moment(date)
      .day(7)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
    return { start: start.valueOf(), end: end.valueOf() }
  }

  const getTimeStepsForDay = stepInMs => {
    const timeSteps = []
    const startTime = '12:00 AM'
    timeSteps.push(startTime)
    let nextTime = moment(startTime, 'hh:mm A')
      .add(stepInMs, 'milliseconds')
      .format('hh:mm A')
    while (!moment(startTime, 'hh:mm A').isSame(moment(nextTime, 'hh:mm A'))) {
      timeSteps.push(nextTime)
      nextTime = moment(nextTime, 'hh:mm A')
        .add(stepInMs, 'milliseconds')
        .format('hh:mm A')
    }
    return timeSteps
  }

  return {
    getDateTimeAsString,
    getDateTimeWoYearAsString,
    getDateAsString,
    getTimeAsString,
    getDurationAsString,
    getTimeStepsForDay,
    getCalendarWeekRangeForADate
  }
})()

export default DateTimeUtils
