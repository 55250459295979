import {
  Box,
  Button,
  DateInput,
  Form,
  FormField,
  Heading,
  Select,
  Text
} from 'grommet'
import React, { useState } from 'react'
import ErrorProvider from './ErrorProvider'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { stringNotEmpty } from '../utils/validations'
import { StringUtils } from 'galarm-shared'
import FormFieldWithNoBorder from './FormFieldWithNoBorder'
import FormFieldWithAllBorders from './FormFieldWithAllBorders'
import { useSelector } from 'react-redux'
import moment from 'moment'
import TimeInput from './TimeInput'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import { SecondaryText } from 'web-components'

const EditShift = ({ isEdit, rotation, shiftId, onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const shift = rotation.shifts.find(shift => shift.id === shiftId)
  const timezone = rotation.timezone
  const rotationParams = rotation.rotationParams

  const rotationType = rotation.type
  let shiftDurationInMilliseconds = 0
  if (rotationType === Constants.ROTATION_TYPES.HOURS) {
    const shiftDuration = parseInt(rotationParams.numHoursInShift)
    shiftDurationInMilliseconds = shiftDuration * 60 * 60 * 1000
  } else if (rotationType === Constants.ROTATION_TYPES.DAYS) {
    const shiftDuration = parseInt(rotationParams.numDaysInShift)
    shiftDurationInMilliseconds = shiftDuration * 24 * 60 * 60 * 1000
  } else if (rotationType === Constants.ROTATION_TYPES.WEEKS) {
    const shiftDuration = parseInt(rotationParams.numWeeksInShift)
    shiftDurationInMilliseconds = shiftDuration * 7 * 24 * 60 * 60 * 1000
  }

  const INITIAL_STATE = isEdit
    ? {
        id: shift.id,
        startDate: shift.start,
        startTime: moment(shift.start).tz(timezone).format('hh:mm a'),
        endDate: shift.end,
        endTime: moment(shift.end).tz(timezone).format('hh:mm a'),
        assignee: shift.assignee,
        timezone: timezone
      }
    : {
        id: doc(collection(GlobalConfig.firestoreDb, 'genIds')).id,
        startDate: Date.now(),
        startTime: '',
        endDate: Date.now() + shiftDurationInMilliseconds,
        endTime: '',
        assignee: '',
        timezone: timezone
      }

  const [value, setValue] = useState(INITIAL_STATE)

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const members = useSelector(state => state.accountInfo.members)
  const membersWithLicense = members.filter(member => member.subscriptionKey)
  const memberOptions = membersWithLicense.map(member => ({
    label: StringUtils.createDisplayName(member.firstName, member.lastName),
    value: member.id
  }))

  const onEditShift = async ({ value }) => {
    console.log('onEditShift', value)

    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: isEdit ? I18n.t('editingShift') : I18n.t('addingShift'),
      closeable: false
    })

    const startDate = moment(value.startDate)
    const startTime = moment(value.startTime, 'hh:mm:a')
    const shiftStartDate = moment().tz(value.timezone)

    // Set the start date
    shiftStartDate.year(startDate.year())
    shiftStartDate.month(startDate.month())
    shiftStartDate.date(startDate.date())
    // Set the start time
    shiftStartDate.hours(startTime.hours())
    shiftStartDate.minutes(startTime.minutes())
    shiftStartDate.seconds(0)
    shiftStartDate.milliseconds(0)

    const endDate = moment(value.endDate)
    const endTime = moment(value.endTime, 'hh:mm:a')
    const shiftendDate = moment().tz(value.timezone)

    // Set the end date
    shiftendDate.year(endDate.year())
    shiftendDate.month(endDate.month())
    shiftendDate.date(endDate.date())
    // Set the end time
    shiftendDate.hours(endTime.hours())
    shiftendDate.minutes(endTime.minutes())
    shiftendDate.seconds(0)
    shiftendDate.milliseconds(0)

    const newShift = {
      id: value.id,
      start: shiftStartDate.valueOf(),
      end: shiftendDate.valueOf(),
      assignee: value.assignee
    }

    try {
      if (isEdit) {
        const shifts = rotation.shifts
        const shiftIndex = shifts.findIndex(shift => shift.id === shiftId)
        shifts.splice(shiftIndex, 1, newShift)
        await updateDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'rotations',
            rotation.id
          ),
          { ...rotation, shifts: shifts }
        )
      } else {
        const shifts = rotation.shifts
        shifts.push(newShift)
        await setDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'rotations',
            rotation.id
          ),
          { ...rotation, shifts: shifts }
        )
      }
      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        isEdit
          ? Constants.UserAnalyticsEvents.EDIT_SHIFT
          : Constants.UserAnalyticsEvents.ADD_SHIFT,
        {}
      )
      onClose()
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  const onSaveStartDate = ({ value: startDate }) => {
    setValue({
      ...value,
      startDate: moment(startDate).valueOf()
    })
  }

  const onSaveStartTime = startTime => {
    setValue({
      ...value,
      startTime: startTime
    })
  }

  const onSaveEndDate = ({ value: endDate }) => {
    setValue({
      ...value,
      endDate: moment(endDate).valueOf()
    })
  }

  const onSaveEndTime = endTime => {
    console.log('onSaveEndTime', endTime)
    setValue({
      ...value,
      endTime: endTime
    })
  }

  const onSaveShiftAssignee = assignee => {
    setValue({
      ...value,
      assignee: assignee
    })
  }

  console.log('EditShift', value)

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center" flex={{ shrink: 0 }}>
        <Heading level={4}>
          {isEdit ? I18n.t('editShift') : I18n.t('addShift')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={shift => {
              onEditShift(shift)
            }}>
            <Box direction="row" border="bottom" pad="small" gap="small">
              <Text>{I18n.t('timezone')}</Text>
              <Text color={'darkTint'}>{rotation.timezone}</Text>
            </Box>
            <Box direction="row" gap="medium">
              <FormField
                htmlFor="startDate"
                name="startDate"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('startDateForShift')}
                  </SecondaryText>
                }
                validate={stringNotEmpty.bind(
                  null,
                  I18n.t('startDateForShift')
                )}>
                <DateInput
                  format="mm/dd/yyyy"
                  value={new Date(value.startDate).toISOString()}
                  onChange={onSaveStartDate}
                />
              </FormField>
              <FormFieldWithNoBorder
                htmlFor="startTime"
                name="startTime"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('startTimeForShift')}
                  </SecondaryText>
                }
                validate={stringNotEmpty.bind(
                  null,
                  I18n.t('startTimeForShift')
                )}>
                <TimeInput time={value.startTime} onChange={onSaveStartTime} />
              </FormFieldWithNoBorder>
            </Box>
            <Box direction="row" gap="medium">
              <FormField
                htmlFor="endDate"
                name="endDate"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('endDateForShift')}
                  </SecondaryText>
                }
                validate={stringNotEmpty.bind(null, I18n.t('endDateForShift'))}>
                <DateInput
                  format="mm/dd/yyyy"
                  value={new Date(value.endDate).toISOString()}
                  onChange={onSaveEndDate}
                />
              </FormField>
              <FormFieldWithNoBorder
                htmlFor="endTime"
                name="endTime"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('endTimeForShift')}
                  </SecondaryText>
                }>
                <TimeInput time={value.endTime} onChange={onSaveEndTime} />
              </FormFieldWithNoBorder>
            </Box>

            <FormFieldWithAllBorders
              htmlFor={'assignee'}
              name={'assignee'}
              validate={stringNotEmpty.bind(null, I18n.t('member'))}>
              <Select
                id={'assignee'}
                name={'assignee'}
                options={memberOptions}
                placeholder={I18n.t('selectPlaceholder')}
                value={value.assignee}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ option }) => {
                  onSaveShiftAssignee(option.value)
                }}
              />
            </FormFieldWithAllBorders>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={isEdit ? I18n.t('save') : I18n.t('add')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default EditShift
