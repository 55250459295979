import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Form, FormField, TextInput, Button, Text, Heading } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n, colors } from 'galarm-config'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { StringUtils, TaskManager } from 'galarm-shared'
import {
  validateEmail,
  validatePassword,
  validateConfirmedPassword,
  validateFirstName,
  validateLastName
} from '../utils/validations'
import ErrorProvider from './ErrorProvider'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  updateProfile
} from 'firebase/auth'
import { createHmacDigest } from '../utils/crypto'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Github, Google } from 'grommet-icons'
import UserContext from './UserContext'

const Signup = () => {
  const [value, setValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  })

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const { user } = useContext(UserContext)
  const captchaRef = useRef(null)
  const navigate = useNavigate()
  // urlParams is an instance of URLSearchParams
  const [urlParams] = useSearchParams()

  // This can be removed if Signup is not the first page
  useEffect(() => {
    async function loadAccount() {
      if (user) {
        const docRef = doc(
          GlobalConfig.firestoreDb,
          'enterpriseUsers',
          user.uid
        )
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          navigate('/app/org')
          return
        }
      }
    }
    loadAccount()
  }, [user])

  const onSignup = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('creatingAnAccount'),
      closeable: false
    })

    const functions = getFunctions()
    const auth = getAuth()

    try {
      const token = captchaRef.current.getValue()
      captchaRef.current.reset()

      const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha')
      const result = await verifyRecaptcha({ token })
      const verificationResponse = result.data

      if (!verificationResponse.success) {
        addError(
          I18n.t('recaptchaFailed', {
            error: verificationResponse['error-codes'].join(', ')
          })
        )
        GlobalConfig.hideProgress()
        return
      }
    } catch (error) {
      console.error('onSignup', error)
      addError(error.message)
      GlobalConfig.hideProgress()
      return
    }

    const { firstName, lastName, email, password } = value
    const passwordHash = createHmacDigest(password)

    // Validate the email
    const emailValid = await TaskManager.addHttpsCloudTask('validateEmail', {
      email
    })
      .then(result => {
        if (!result.valid) {
          addError(I18n.t('invalidEmail', { reason: result.reason }))
          GlobalConfig.hideProgress()
          return false
        }
        return true
      })
      .catch(error => {
        console.error('onSignup', error)
        addError(I18n.t('unableToValidateEmail', { error: error.error }))
        GlobalConfig.hideProgress()
        return false
      })

    if (!emailValid) {
      return
    }

    let userCredential
    try {
      userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
    } catch (error) {
      console.error('onSignup', error)
      addError(error.message)
      GlobalConfig.hideProgress()
      return
    }

    // Signed in
    const user = userCredential.user
    const uid = user.uid

    try {
      await updateProfile(user, {
        displayName: StringUtils.createDisplayName(firstName, lastName)
      })

      // add referral info if present
      let referralInfo = null
      if (urlParams.has('utm_source')) {
        const utmSource = urlParams.get('utm_source')
        if (utmSource === 'affiliate' && urlParams.has('affiliate_id')) {
          const affiliateId = urlParams.get('affiliate_id')
          referralInfo = {
            utmSource: utmSource,
            affiliateId: affiliateId
          }

          const affiliateDoc = doc(
            GlobalConfig.firestoreDb,
            'affiliates',
            affiliateId
          )
          const affiliateDocSnap = await getDoc(affiliateDoc)
          if (affiliateDocSnap.exists()) {
            const affiliateData = affiliateDocSnap.data()
            if (affiliateData) {
              const affiliateReferralCount = affiliateData.referralCount
              await setDoc(
                affiliateDoc,
                {
                  referralCount: affiliateReferralCount + 1
                },
                { merge: true }
              )
            }
          } else {
            // Create a new affiliate
            await setDoc(affiliateDoc, {
              id: affiliateId,
              referralCount: 1
            })
          }
        }
      }

      await setDoc(doc(GlobalConfig.firestoreDb, 'enterpriseUsers', uid), {
        id: uid,
        firstName,
        lastName,
        email,
        password: passwordHash,
        referralInfo: referralInfo
      })

      GlobalConfig.hideProgress()
      navigate('/app', { replace: true })

      const sendEmailVerificationLink = httpsCallable(
        functions,
        'sendEmailVerificationLink'
      )
      await sendEmailVerificationLink({
        email: email,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    } catch (error) {
      // const errorCode = error.code
      // const errorMessage = error.message
      console.error('onSignup', error)
      addError(error.message)

      const deleteUser = httpsCallable(functions, 'deleteUser')
      await deleteUser()
      GlobalConfig.hideProgress()
    }
  }

  const signInWithGoogle = async () => {
    const auth = getAuth()
    try {
      const googleAuthProvider = new GoogleAuthProvider()
      const res = await signInWithPopup(auth, googleAuthProvider)
      const user = res.user
      console.log('user', user)
      const userQuery = query(
        collection(GlobalConfig.firestoreDb, 'enterpriseUsers'),
        where('uid', '==', user.uid)
      )
      const docs = await getDocs(userQuery)
      if (docs.docs.length === 0) {
        await setDoc(
          doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid),
          {
            id: user.uid,
            firstName: user.displayName.split(' ')[0],
            lastName: user.displayName.split(' ')[1],
            name: user.displayName,
            authProvider: 'google',
            email: user.email
          }
        )
        navigate('/app', { replace: true })
      }
    } catch (err) {
      console.error(err)
      addError(err.message)
    }
  }

  const signInWithGithub = async () => {
    const auth = getAuth()
    try {
      const res = await signInWithPopup(auth, new GithubAuthProvider())
      const user = res.user
      console.log('user', user)
      const userQuery = query(
        collection(GlobalConfig.firestoreDb, 'enterpriseUsers'),
        where('uid', '==', user.uid)
      )
      const docs = await getDocs(userQuery)
      if (docs.docs.length === 0) {
        await setDoc(
          doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid),
          {
            id: user.uid,
            firstName: user.displayName.split(' ')[0],
            lastName: user.displayName.split(' ')[1],
            name: user.displayName,
            authProvider: 'github',
            email: user.email
          }
        )

        navigate('/app', { replace: true })
      }
    } catch (err) {
      console.error(err)
      addError(err.message)
    }
  }

  const contactSupport = () => {
    window.open('mailto:galarm@acintyo.com')
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box flex={{ shrink: 0 }} align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('signup')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={userData => {
              onSignup(userData)
            }}>
            <Box gap="xsmall">
              <Box direction="row" gap="medium">
                <FormField
                  flex
                  htmlFor="firstName"
                  name="firstName"
                  validate={validateFirstName}>
                  <TextInput
                    id="firstName"
                    name="firstName"
                    placeholder={I18n.t('firstName')}
                  />
                </FormField>
                <FormField
                  flex
                  htmlFor="lastName"
                  name="lastName"
                  validate={validateLastName}>
                  <TextInput
                    id="lastName"
                    name="lastName"
                    placeholder={I18n.t('lastName')}
                  />
                </FormField>
              </Box>
              <FormField htmlFor="email" name="email" validate={validateEmail}>
                <TextInput
                  id="email"
                  name="email"
                  placeholder={I18n.t('email')}
                />
              </FormField>
              <FormField
                htmlFor="password"
                name="password"
                validate={validatePassword}>
                <TextInput
                  type="password"
                  id="password"
                  name="password"
                  placeholder={I18n.t('password')}
                />
              </FormField>
              <FormField
                htmlFor="confirmPassword"
                name="confirmPassword"
                validate={(confirmPassword, formValue) =>
                  validateConfirmedPassword(formValue.password, confirmPassword)
                }>
                <TextInput
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder={I18n.t('confirmPassword')}
                />
              </FormField>
            </Box>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </Box>
            <Box direction="row" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('signup')}
              />
            </Box>
            <Box direction="row" justify="center" margin={{ bottom: 'small' }}>
              <Box
                pad={{ horizontal: 'small' }}
                direction="row"
                align="center"
                justify="center"
                gap="small"
                border
                round="small"
                borderRadius="small">
                <Text size="small" color="darkTint">
                  {I18n.t('otherSignupOptions')}
                </Text>
                <Button icon={<Google />} onClick={signInWithGoogle} />
                <Button icon={<Github />} onClick={signInWithGithub} />
              </Box>
            </Box>
            <Text size="small" color="darkTint">
              {I18n.t('bySigningUpYouAgreeToOur')}{' '}
              <Link to="/termsOfService" style={{ color: colors.blue }}>
                {I18n.t('termsOfService')}
              </Link>{' '}
              {I18n.t('and')}{' '}
              <Link to="/privacyPolicy" style={{ color: colors.blue }}>
                {I18n.t('privacyPolicy')}
              </Link>
            </Text>
          </Form>
        </Box>
        <Box direction="row" gap="small" justify="center">
          <Text>{I18n.t('alreadySignedUp')}</Text>
          <Link to="/login">{I18n.t('login')}</Link>
        </Box>
        <Box direction="row" gap="small" justify="center" pad="medium">
          <Text>{I18n.t('havingProblems')}</Text>
          <Button
            plain
            color="primary"
            label={I18n.t('contactSupport')}
            onClick={contactSupport}
          />
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default Signup
