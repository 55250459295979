import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalConfig, I18n } from 'galarm-config'
import { SecondaryText } from 'web-components'
import { isNumber } from '../utils/validations'
import { useDispatch } from 'react-redux'
import { doc, getDoc } from 'firebase/firestore'
import UserContext from './UserContext'
import ActionCreators from '../actions/creators'

const CloseIncidents = ({ alert, onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const enterpriseUser = useRef(null)
  const { user } = useContext(UserContext)

  const [value, setValue] = useState({ olderThanHours: '24' })
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchEnterpriseUser = async () => {
      const enterpriseUserDoc = doc(
        GlobalConfig.firestoreDb,
        'enterpriseUsers',
        user.uid
      )
      const enterpriseUserSnap = await getDoc(enterpriseUserDoc)
      enterpriseUser.current = enterpriseUserSnap.data()
    }
    fetchEnterpriseUser()
  }, [user])

  const closeIncidents = async ({ value }) => {
    console.log(
      'Closing incidents',
      enterpriseAccountId,
      alert.id,
      value.olderThanHours
    )
    dispatch(
      ActionCreators.closeIncidents(
        enterpriseAccountId,
        alert.id,
        parseInt(value.olderThanHours),
        enterpriseUser.current
      )
    )
    onClose()
  }

  // Get olderThanHours input from user and invoke the closeIncidents API
  return (
    <Box flex={{ shrink: 0 }} align="center">
      <Heading level={4} margin={{ left: 'medium' }}>
        {I18n.t('closeIncidents')}
      </Heading>
      <Box
        background="white"
        pad="small"
        round="small"
        margin="medium"
        width="large">
        <Form
          value={value}
          onChange={nextValue => setValue(nextValue)}
          validate="submit"
          onSubmit={closeIncidents}>
          <FormField
            htmlFor="olderThanHours"
            name="olderThanHours"
            label={
              <SecondaryText weight={500}>
                {I18n.t('olderThanHours')}
              </SecondaryText>
            }
            validate={isNumber.bind(null, I18n.t('olderThanHours'))}>
            <TextInput id="olderThanHours" name="olderThanHours" />
          </FormField>
          <Box direction="row" gap="medium" justify="center" margin="medium">
            <Button
              type="submit"
              primary
              style={{ color: 'white' }}
              label={I18n.t('closeIncidents')}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default CloseIncidents
